@charset "UTF-8";

/*termsofuse*/
.termsofuse{
  position:fixed;
  width:100%;
  height:100%;
  z-index:1000;
  display:none;
}
.termsofuse_bg{
  position:fixed;
  width:100%;
  height:100%; 
  background-color:rgba(0,0,0,0.25);
}
.termsofuse_main{
  position:absolute;
  width:600px;
  height:auto;/*430px;*/
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  border-radius:1.2rem;
  background-color:#fff;
  overflow:hidden;
  box-shadow:1px 1px 5px rgba(0,0,0,0.25);
  transition: .5s opacity .5s ease-out;
  opacity:0;
  z-index:2;
}
.termsofuse_main.mdl_show{
  opacity:1;
}
.termsofuse_close{
  position:absolute;
  width:17px;
  height:17px;
  top:18px;
  right:21px;
  z-index:2;
}
.termsofuse_close a{
  position:relative;
  display:block;
}
.termsofuse_close a span{
  visibility:hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.termsofuse_close a::before {
  content: '';
  position:absolute;
  display: block;
  height:3px;
  width:21px;
  top:0;
  left:0;
  background-color: #b2b2b2;
  border-radius:1.5px;
}
.termsofuse_close a::before {
  -webkit-transform: translateY(0.75rem) rotate(45deg);
  transform: translateY(0.75rem) rotate(45deg);
}
.termsofuse_close a::after {
  content: '';
  position:absolute;
  display: block;
  height:3px;
  width:21px;
  bottom:2px;
  left:0;
  background-color: #b2b2b2;
  border-radius:1.5px;
}
.termsofuse_close a::after {
  -webkit-transform: translateY(-0.75rem) rotate(-45deg);
  transform: translateY(-0.75rem) rotate(-45deg);
}
.termsofuse_cont{
  padding:3rem 2.2rem 2.8rem 2.2rem;
}
.termsofuse_modal_ttl{
  text-align:center;
  margin-bottom:2rem;
  width:100%;
  font-size:2rem;
  line-height:1.9;
  font-weight:600;
  color:#000;
}
.termsofuse_modal_txt{
  margin-bottom:3rem;
  text-align:center;
  font-size:1.1rem;
  line-height:1.9;
  color:#404142;
}
.termsofuse_detail{
  margin-bottom:2.4rem;
  padding:1.3rem;
  width:100%;
  height:142px;
  font-size:1.1rem;
  line-height:1.8;
  color:#404142;
  border-radius:.4rem;
  border:1px solid #595757;
  box-sizing:border-box;
  overflow:hidden;
  overflow-y:scroll;
}
.termsofuse_join_btn{
  display:block;
  margin:0 auto;
  padding:0 1.8rem;
  width:22rem;
  height:3.2rem;
  font-size:1.2rem;
  line-height:3.2rem;
  font-weight:400;
  color:#fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #2286fb;
  cursor: pointer;
  border-radius:0.33rem;
}

/*--------------------------*/
/*register_login*/
/*--------------------------*/
.login_main{
  padding-bottom:4rem;
  width:100%;
  height:100vh;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  overflow-y: scroll;
  background-color:#fff;
}
.login_body{
  flex-grow:1;
  font-size:1.3rem;
  max-width:41em;
  color:#535353;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.login_body .login_logo{
  display: block;
  margin:0 auto;
  width:33rem;
}
.login_body .login_logo span{
  display:block;
  margin-top:2.2rem;
  font-size:2.3rem;
  line-height:1;
  font-weight:400;
  text-align:center;
}
.rgst-form {
  margin:0 auto;
  margin-top:3rem;
  text-align: left;
  width:100%;
}
.rgst-form-name{
  margin-left:auto;
  margin-right:auto;
  display:flex;
  justify-content:space-between;
  width:33rem;
  height:100%;
}
.rgst-form-col{
  margin-left:auto;
  margin-right:auto;
  width:33rem;
}
.rgst-form-name .rgst-form-col{
  margin-left:0;
  margin-right:0;
  width:16rem;
}
.rgst-form-col > input:not([type=submit]) {
  display: block;
  font-size: 1.6rem;
  line-height: 1;
  color: #1e1e1e;
  padding: 1.0rem;
  height: calc( 2.8rem + 1.6rem + 2px);
  width: 100%;
  outline: 0;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: .4rem;
}
.rgst-form-col input::placeholder {
  color:#aaaaa9;
}
.rgst-form-col input:-ms-input-placeholder {
  color:#aaaaa9;
}
.rgst-form-col input::-ms-input-placeholder {
  color:#aaaaa9;
}
.rgst-form-col.submit{
  margin-bottom:0;
}
.rgst-form-col.login_btn{
  margin-top:2.5rem;
  margin-bottom:0;
}
.rgst-privacy{
  margin-top:3rem;
  margin-bottom:3rem;
  text-align:center;
  line-height:1.8;
}
.rgst-privacy .passwd_txt{
  display:block;
  padding-bottom:.5rem;
}
.login .rgst-privacy{
  margin-bottom:0;
}
.password1 .rgst-privacy{
  margin-bottom:0;
}
.rgst-sendmail{
  margin-top:3rem;
  margin-bottom:0;
  font-size:1.6rem;
  text-align:center;
  line-height:1.8;
}
.rgst-privacy a{
  color:#2286fb;
  text-decoration:underline;
}
.rgst-form-submit {
  display:block;
  margin:0 auto;
  padding: 1.5rem 3rem;
   width: 16rem;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #4285f4;
  cursor: pointer;
  /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
  border-radius: 0.33rem;
  transition: opacity .5s ease-out;
}
.rgst-form-submit:hover {
  opacity:0.7;
  /* background-color: #4285f4; */
  /* text-decoration: none; */
}
/* .rgst-form-submit:focus, .rgst-form-submit.focus { outline: 0; } */
/* .rgst-form-submit.disabled, .rgst-form-submit:disabled { opacity: 0.65; } */

.rgst-address {
  margin-top: 4.4rem;
  margin-bottom: 0;
  padding-bottom: 7.2rem;
  font-size: 1.8rem;
  line-height: 1;
  color: #2286fb;
  background: url(../images/main/icon_mailsend.svg);
  background-repeat:no-repeat;
  background-position:center bottom 0;
  background-size:98px auto;
}

/*login_footer*/
.login_footer{
  position:absolute;
  padding-bottom:1.4rem;
  bottom:10px;
  left:0;
  width: 100%;
  text-align: center;
}
.footer_list{
  margin-bottom:1rem;
  display:flex;
  justify-content:center;
}
.footer_list li{
  position:relative;
  margin-right:2.5rem;
}
.footer_list li::after{
  content:'|';
  position:absolute;
  top:50%;
  right:-1.5rem;
  transform:translate(0,-50%);
  color:#535353;
  font-size:1.2rem;
  line-height:1;
}
.footer_list li:last-child{
  margin-right:0;
}
.footer_list li:last-child::after{
  content:none;
}
.footer_list li a{
  text-decoration:none;
  color:#535353;
  font-size:1.2rem;
  line-height:1;
}
.footer_copyright{
  margin-bottom:0;
  font-size:1.2rem;
  color:#6c6c6c;
}