@charset "UTF-8";

body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td,figure{margin:0;padding:0;}
h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;}
input,button,textarea,select{margin:0;padding:0;background:none;border:none;border-radius:0;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none}
html,body{margin:0;padding:0;width:100%;}
section,footer,header{margin:0;padding:0}
ul{list-style:none}ul li{vertical-align:top}
label {display: inline-block;margin-bottom: 0.5rem;}
button, input {overflow: visible;}
button, select {text-transform: none;}
input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input:-internal-autofill-selected{
  background-color:transparent;
}
button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {cursor: pointer;}
button, [type="button"], [type="reset"], [type="submit"] {-webkit-appearance: button;}
*, *::before, *::after {box-sizing: border-box;}
em {font-style: normal;}
p {margin-top: 0;margin-bottom: 1rem;}
a {outline: none;}a img {outline:none;}a:focus, *:focus {outline:none;}
a:link{text-decoration:none;}a:visited{text-decoration:none;}a:active{text-decoration:none;}
a:hover{
  text-decoration:none;
  opacity:0.7;
  transition:.4s;
  filter: alpha(opacity=70);
  -ms-filter: "alpha(opacity=70)";
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #5e5f5f;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
html {
  height:100%;
}
html{
  font-size:62.5%;
}
body{
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
	font-size:1.2rem;
  font-weight:400;
	-webkit-text-size-adjust:100%;
  text-align: left;
	color:#4a4a4a;
  height:100%;
  background-color:#fff;
}
img {
	width:100%;
	height:auto;
	vertical-align:bottom;
}
img[src$=".svg"]{
  width:100%;
  height:auto;
}
.invisible {
  visibility: hidden !important;
}


/*--------------------------------
home_header
----------------------------------*/
.home_header {
  width:100%;
  height:50px;
  border-bottom: 1px solid #eaeaea;
}
.home_header .innr{
  padding:13px;
  display:flex;
  align-items:center;
}
.home_header .home_header_logo{
  margin:0;
  width:171px;
  height:auto;
  line-height:inherit;
}
.home_header .home_header_logo a:hover{
  opacity:1;
}

.home_header .home_header_range{
  position:relative;
  margin-left:39px;
  cursor:pointer;
}
.home_header_range a:hover{
  opacity:1;
}
.home_header .headerrange{
  position:relative;
  width:34px;
  height:16px;
}
.home_header .headerrange::before{
  content: '';
  display: block;
  position: absolute;
  width:34px;
  height:16px;
  top: 50%;
  left:0;
  transform:translate(0,-50%);
  border-radius:16px;
  background-color:#dfdfdf;
}
.home_header .headerrange::after{
  content: '';
  display: block;
  position: absolute;
  background-color: #4684f4;
  width:14px;
  height:14px;
  border-radius: 50%;
  top:1px;
  left:1px;
  transition: left .3s ease-out;
}
.home_header .headerrange.range_tight::after{
  left:1px;
}
.home_header .headerrange.range_middle::after{
  left:10px;
}
.home_header .headerrange.range_wide::after{
  left:19px;
}

.home_header .header_right{
  position:absolute;
  top:13px;
  right:13px;
  display:flex;
  align-items:center;
}
.home_header .home_header_info{
  display:flex;
  align-items:center;
  list-style:none;
}
.home_header .home_header_info li{
  vertical-align:top;
}
.home_header .home_header_info li.message{
  margin-right:25px;
}
.home_header .home_header_info li.notice{
  margin-right:30px;
}
.home_header .home_header_info li.helpcenter{
  margin-left:25px;
}
.home_header .home_header_info li.message a:hover,
.home_header .home_header_info li.notice a:hover{
  opacity:1;
}
.home_header .icon_message{
  position:relative;
  width:20px;
  height:16px;
  background-image:url("../images/main/icon_message.svg");
  background-repeat:no-repeat;
  background-position:center;
  background-size:100%;
  transition:.3s ease-out;
}
.home_header .icon_message:hover{
  opacity:1;
  background-image:url("../images/main/icon_message_o.svg");
}
.home_header .icon_message.icon_active{
  background-image:url("../images/main/icon_message_o.svg");
}
.home_header .icon_notice{
  position:relative;
  width:14px;
  height:19px;
  background-image:url("../images/main/icon_notice.svg");
  background-repeat:no-repeat;
  background-position:center;
  background-size:100%;
  transition:.3s ease-out;
}
.home_header .icon_notice:hover{
  opacity:1;
  background-image:url("../images/main/icon_notice_o.svg");
}
.home_header .icon_notice.icon_active{
  background-image:url("../images/main/icon_notice_o.svg");
}
.home_header_info_icon {
  position: relative;
  display: block;
  height: 100%;
  outline: none;
  text-decoration: none;
}
.home_header_info_icon .icon_count {
  position: absolute;
  left:60%;
  top:-1rem;
  display: block;
}
.home_header_info_icon .icon_count_value {
  display: block;
  background-color:#ff0000;
  border-radius:8px;
  color:#fff;
  font-size: .6rem;
  font-weight:600;
  line-height:1;
  padding:3px 6px;
}
.home_header_account{
  position:relative;
}
.home_header_account .dropdown_toggle {
  position:relative;
  vertical-align:middle;
  font-size:1.3rem;
  line-height:1;
  font-feature-settings:"palt";
  text-decoration: none;
  color:#606060;/*#979797;*/
  transition:opacity .3s ease-out;
}
.home_header_account .dropdown_toggle::after {
  content: '';
  width:0;
  height:0;
  display:inline-block;
  border:4px solid transparent;
  border-bottom:none;
  border-top-color:#979797;
  margin-left:0.57143em;
  vertical-align: middle;
}
.home_header_info .helpcenter_link{
  display:block;
  padding:0 13px;
  height:24px;
  vertical-align: middle;
  text-decoration: none;
  font-size:1.06rem;
  line-height:24px;
  font-weight:700;
  color:#2286fb;
  border: 1px solid #2286fb;
  border-radius:3px;
}
/*page-header*/
// .home_header_info .helpcenter_link.page{
//   background-color:#2286fb;
// }
.page-header-backto {
  line-height: 1.8;
  margin: .5rem 0;
}
.page-header-backto::before {
  display: block;
  content: url(../images/main/icon_arrow_backto.svg);
  width: 1.7rem;
  height: 1.7rem;
}
.page_header_ttl {
  margin-bottom:0;
  margin-left:1.6rem;
  font-size:1.6rem;
  line-height:1.6;
  color:#5d5f5f;
}


/*main_conts*/
#main_conts{
  position:relative;
  height:calc( 100% - 50px );
  width:100%;
  overflow:hidden;
  display:flex;
  justify-content:space-between;
}

/*home_nav*/
.home_nav{
  position:relative;
  width:10px;/*210px;*/
  height:100%;
  overflow:hidden;
  background-color:#f7f7f7;
  transition: width .3s ease;
}
.home_nav.isOpen{
  width:275px;
}
/*page_aside_nav*/
.home_nav.page_aside_nav{
  width:260px;
  border-right:1px solid #e2e2e2;
  box-sizing:border-box;
}
.home_nav_body{
  padding-right:10px;
  width:100%;/*200px;*/
  height:100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.home_nav.page_aside_nav .home_nav_body{
  padding:0;
}
.home_nav_ws{
  position: relative;
  padding:0 6px;
  padding-top:.5rem;
  padding-bottom:10rem;
}
.home_nav_ws .collapse:not(.show) {
  display: none;
}
.home_nav_ws > a:hover{
  opacity:1;
}
.home_nav_ws > dl {
  margin-top:1.5rem;
}
.home_nav_ws > dl:first-child{
  margin-top:0;
}
.home_nav_ws_ttl{
  margin-top:1rem;
}
.home_nav_ws_ttl a {
  display:block;
  padding: .6rem .7rem;
  font-size: 1.4rem;
  line-height: 1;
  font-weight:400;
  
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  border-radius: .4rem;
  color:#4a4a4a;
}
.home_nav_ws_ttl a:hover{
  opacity:1;
  background-color:#e2e2e2;
}
.home_nav_ws_ttl a::before {
  content: '';
  display: inline-block;
  width:0;
  height:0;
  top:50%;
  margin-top:-0.45rem;
  border-style: solid;
  border-width: .6rem;
  border-color: transparent;
  border-top-color:#989898;
  border-bottom:none;
  vertical-align:middle;
  margin-right: .5rem;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
}
.home_nav_ws_ttl a.list_close::before {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.home_nav_ws_projs{
  display:block;
  padding:0;
  list-style:none;
}
.home_nav_ws_proj{
  display:block;
  margin-top: .2rem;
  list-style: none;
  padding-left:0;
  transition: opacity .3s ease-out;
  opacity: .35;
}
.home_nav_ws_proj.select_proj{
  opacity:1!important;
}
.home_nav_ws_proj_wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 .7rem;
  border-radius: .4rem;
  transition: background-color .3s ease-out;
  /*opacity: .35;*/
}
.home_nav_ws_proj_wrap:hover{
  /*opacity:1;*/
  background-color:#e2e2e2;
}
.home_nav_ws_proj_label {
  position:relative;
  padding-left:1.5em;
  font-size: 1.1rem;
  line-height: 2.36364;
  cursor: pointer;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.home_nav_ws_proj_label::before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1em;
  height: 1em;
  border-radius: 3px;
  background-color: #c5c8d1;
  margin-top: .7em;
}
.home_nav_ws_proj_label::after{
  position: absolute;
  content: '';
  display: block;
  width: 1em;
  height: 1em;
  visibility: hidden;
  left:0;
  top:50%;
  transform:translate(0,-50%);
  background: url(../images/main/check_proj.svg) 50% 50% no-repeat;
}
.home_nav_ws_proj:active,
.home_nav_ws_proj_wrap:active,
.home_nav_ws_proj_label:active{
  cursor:move!important;
}
.drag_chosen {
  /*opacity:1;*/
  border-radius:.4rem;
  background-color:#e2e2e2;
}
.home_nav_ws_proj.select_proj .home_nav_ws_proj_label::after{
  visibility:visible;
}
/*theme_clr*/
.theme_clr1 .home_nav_ws_proj_label::before{ background-color: #e07981; }
.theme_clr2 .home_nav_ws_proj_label::before{ background-color: #ec8b74; }
.theme_clr3 .home_nav_ws_proj_label::before{ background-color: #f7d371; }
.theme_clr4 .home_nav_ws_proj_label::before{ background-color: #a7d16f; }
.theme_clr5 .home_nav_ws_proj_label::before { background-color: #7fd2b9; }
.theme_clr6 .home_nav_ws_proj_label::before { background-color: #80c6e6; }
.theme_clr7 .home_nav_ws_proj_label::before { background-color: #7aa4e8; }
.theme_clr8 .home_nav_ws_proj_label::before { background-color: #b09ee9; }
.theme_clr9 .home_nav_ws_proj_label::before { background-color: #e198c4; }
.theme_clr10 .home_nav_ws_proj_label::before { background-color: #b1a78b; }
.theme_clr11 .home_nav_ws_proj_label::before { background-color: #9a8c80; }
.theme_clr12 .home_nav_ws_proj_label::before { background-color: #7a8a9f; }
.theme_clr13 .home_nav_ws_proj_label::before { background-color: #939393; }
.theme_clr14 .home_nav_ws_proj_label::before { background-color: #c5c8d1; }

/*home_nav_create*/
.home_nav_create{
  position:absolute;
  height:57px;
  bottom:0;
  left:0;
  width:calc(100% - 10px);/*200px;*/
  background-color:#f7f7f7;
}
.home_nav_create::before {
  content: '';
  position: absolute;
  display: block;
  height: 0;
  width: 100%;
  padding-top: 2.6rem;
  margin-top: -2.6rem;
  /*background-image:linear-gradient(to top, #f7f7f7 5%, transparent);*/
  background:linear-gradient(to top, #f7f7f7 5%, rgba(247,247,247,0) );
  background-repeat: repeat-x;
  z-index: 10;
}
.home_nav_archive a{
  display: block;
  position:absolute;
  width:32px;
  top:1.3rem;
  right:20px;
}

.home_nav_plus a {
  display: block;
  width: 3.8rem;
  height: 3.8rem;
  margin: .5rem 0 0 1.3rem;
  background-color: #2286fb;
  border-radius: 50%;
  position: relative;
  transition: opacity .3s ease-out;
}
.home_nav_plus a::before, .home_nav_plus a::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: white;
}
.home_nav_plus a::before {
  width: .2rem;
  height: 1.6rem;
}
.home_nav_plus a::after {
  width: 1.6rem;
  height: .2rem;
}

/*home_nav_toggle*/
.home_nav_toggle {
  position:absolute;
  display:block;
  width:10px;
  height:100%;
  top:0;
  right:0;
  background-color:#dfdfdf;
  z-index:10;
}
.home_nav_toggle::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom:5%;
  right: 0;
  margin: auto;
  border: 6px solid transparent;
  border-left-color: #666666;
  border-right: none;
  transition: -webkit-transform .4s ease-out;
  transition: transform .4s ease-out;
  transition: transform .4s ease-out, -webkit-transform .4s ease-out;
}
.home_nav.isOpen .home_nav_toggle::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.page_aside_nav .home_nav_ws{
  padding:2.2rem 1.6rem;
}
/*page_nav_list*/
.page_nav_list{
  display:block;
}
.page_nav_list li{
  display:block;
  margin-bottom:1rem;
}
.page_nav_list li:last-child{
  margin-bottom:0;
}
.page_nav_list li a{
  display:block;
  padding-left:1.2rem;
  font-size:1.5rem;
  line-height:2.2;
  color:#a4a4a4;
  border-radius:.5rem;
  transition: all .3s ease-in;
  opacity:1;
}
.page_nav_list li a.active{
  color:#fff;
  background-color:#2286fb;
}
.page_nav_list li a:hover{
  color:#fff;
  background-color:#2286fb;
}

/*home_main*/
#ganttchart {
  width: 100%;
  height: 100%;
}
.home_main{
  position:relative;
  width:100%;
  height:100%;
  overflow-y: scroll;
}
.home_main_innr{
  position:relative;
  width: 100%;
  height: 100%;
}

/*page_main*/
.page_main {
  position: relative;
  margin: 0 auto;
  width: 100%;
  color: #5d5f5f;

  /* padding-bottom:8rem; */
  /* max-width:956px; */
  /* height:100%; */
  
  &_inner {
    padding:2.2rem 28px;
  }
}